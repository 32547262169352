<template>
    <v-card height="100%">
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit transaction</v-toolbar-title>

            <v-spacer />

            <v-btn small text @click="openDeleteConfirm">Delete</v-btn>
            <v-btn small text :disabled="saveDisabled" @click="save"
                >Save</v-btn
            >
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form class="mt-3">
                    <v-row align="end">
                        <v-btn outlined block @click="openItemPicker">
                            <v-icon>mdi-magnify</v-icon>
                            <span>Change Product</span>
                        </v-btn>
                    </v-row>
                    <v-row v-if="itemCode">
                        <div class="item-code mt-3">
                            {{ itemCode }}
                        </div>
                    </v-row>
                    <v-row v-if="itemDescription">
                        <div class="item-description mt-3">
                            {{ itemDescription }}
                        </div>
                    </v-row>
                    <v-row v-if="itemCode && itemDescription">
                        <v-text-field
                            class="mt-3"
                            label="Quantity"
                            type="number"
                            v-model="itemQuantity"
                            placeholder="Quantity"
                            :rules="quantityRules"
                        />
                    </v-row>
                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import payments from "./paymentsAgent";
import { required, isInteger, greaterThan } from "@/validation";

const GoodsAndServicesPicker = () => import("./GoodsAndServicesPicker");

export default {
    props: {
        payment: {
            required: true,
        },
        transactionId: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            status: "",
            itemCode: "",
            itemQuantity: -1,
            itemDescription: "",
            amount: -1,
            vatAmount: -1,
            transactionType: "",
            itemUnitOfMeasure: "",
            itemPrice: -1,
            adjustmentCode: "",
            adjustmentDescription: "",
            adjustmentRate: -1,
            manualAmountEntry: -1,
            chartOfAccountsCode: null,
        };
    },
    computed: {
        quantityRules: function () {
            return [required(), isInteger(), greaterThan(0)];
        },
        saveDisabled: function () {
            return (
                !this.itemCode ||
                !this.itemDescription ||
                !(this.itemQuantity > 0)
            );
        },
    },
    watch: {
        itemQuantity: function (value) {
            this.amount = this.itemPrice * value;
        },
    },
    methods: {
        openDeleteConfirm: function () {
            const vm = this;
            vm.$coreUi.messageBox({
                title: "Delete Transaction",
                message: "Do you want to delete this transaction?",
                btns: [
                    {
                        text: "Yes",
                        color: "error",
                        handler: async () => {
                            vm.status = "deleted";
                            await vm.save();
                        },
                    },
                    {
                        text: "No",
                    },
                ],
            });
        },
        openItemPicker: function () {
            this.$coreUi.dialog(
                GoodsAndServicesPicker,
                {
                    sharedoId: this.payment.parentSharedoId,
                    paymentRequestId: this.payment.aspectData.paymentRequest.id,
                },
                {
                    closing: (result) => {
                        if (result) {
                            this.itemCode = result.itemCode;
                            this.itemDescription = result.itemDescription;
                            this.amount = result.value * this.itemQuantity;
                            this.vatAmount = result.vatRate;
                            this.itemUnitOfMeasure = result.unitOfMeasurement;
                            this.itemPrice = result.value;
                        }
                    },
                }
            );
        },
        save: async function () {
            const loading = this.$coreUi.loading();

            try {
                const request = {
                    paymentRequestModel: {
                        parentSharedoId: this.payment.parentSharedoId,
                        paymentRequestType: this.payment.sharedoTypeSystemName,
                        supplierOdsId:
                            this.payment.aspectData.paymentRequest
                                .supplierOdsId,
                        rechargeToOdsId:
                            this.payment.aspectData.paymentRequest
                                .rechargeToOdsId,
                        details: this.payment.aspectData.paymentRequest.details,
                        transactionItems: [
                            {
                                id: this.transactionId,
                                status: this.status,
                                chartOfAccountsCodeIds: [
                                    this.chartOfAccountsCode.id,
                                ],
                                transactionType: this.transactionType,
                                currency: "GBP",
                                amount: this.amount,
                                vatAmount: this.vatAmount,
                                itemCode: this.itemCode,
                                itemDescription: this.itemDescription,
                                itemUnitOfMeasure: this.itemUnitOfMeasure,
                                itemQuantity: this.itemQuantity,
                                itemPrice: this.itemPrice,
                                adjustmentCode: this.adjustmentCode,
                                adjustmentDescription:
                                    this.adjustmentDescription,
                                adjustmentRate: this.adjustmentRate,
                                manualAmountEntry: this.manualAmountEntry,
                            },
                        ],
                    },
                };

                await payments.savePayment(this.payment.id, request);
                this.close(true);
            } catch (error) {
                console.error(error);
            } finally {
                loading.dismiss();
            }
        },
        close: function (result) {
            this.$emit("close", result);
        },
    },
    mounted: async function () {
        const chartOfAccountsPromise = payments.getChartOfAccountsCode(
            this.payment.parentSharedoId,
            this.payment.sharedoTypeSystemName
        );

        const transaction =
            this.payment.aspectData.paymentRequest.transactionItems.find(
                (item) => item.id === this.transactionId
            );

        this.status = transaction.status;
        this.itemCode = transaction.itemCode;
        this.itemQuantity = transaction.itemQuantity;
        this.itemDescription = transaction.itemDescription;
        this.amount = transaction.amount;
        this.vatAmount = transaction.vatAmount;
        this.transactionType = transaction.transactionType;
        this.itemUnitOfMeasure = transaction.itemUnitOfMeasure;
        this.itemPrice = transaction.itemPrice;
        this.adjustmentCode = transaction.adjustmentCode;
        this.adjustmentDescription = transaction.adjustmentDescription;
        this.adjustmentRate = transaction.adjustmentRate;
        this.manualAmountEntry = transaction.manualAmountEntry;

        this.chartOfAccountsCode = await chartOfAccountsPromise;
    },
};
</script>